import { useNavigate } from "react-router-dom";
import CyclicGallery from "./CyclicGallery";
import headshot from "../app/assets/img/P1010439small.jpg";
import "./componentcss/header.css";


const Header = (props) => {
  const { headline, tagline, display, headshotDisplay } = props;
  const navigate = useNavigate();

  return (
    <div className=" header ">
      <div className="overlay">
        <h1 className="headline">{headline}</h1>
        <div style={{ display: headshotDisplay }}>
          <img src={headshot} alt="Kevin's Balloons" style={{borderRadius: "50%", border: "3px solid #ffb200", maxHeight: "200px"}}/>
        </div>
        <p>{tagline}</p>
        
        <div className="  header-btn-container" style={{ display: display }}>
        <button className="decor-btn" onClick={()=>{navigate('/services')}}>
            Order Decor
          </button>
          <button className="booking-btn" onClick={()=>{navigate('/book')}}>
            Book Kevin 
          </button>

        </div>
      </div>
      <CyclicGallery />
    </div>
  );
};

export default Header;
