// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1iFVoPMFzNsRZQiNE6MfDWbAi5c0W_ho",
  authDomain: "kevinsballoons.firebaseapp.com",
  projectId: "kevinsballoons",
  storageBucket: "kevinsballoons.appspot.com",
  messagingSenderId: "184944727970",
  appId: "1:184944727970:web:41ae5dc326ce84228604fb",
  measurementId: "G-QE23TRLFFD"
};

// Initialize Firebase
 const app = initializeApp(firebaseConfig);
 const analytics = getAnalytics(app);
 export const db = getFirestore(app)