import React, { useState } from "react";
import "../twistingform/twistingform.css";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import owlimg from "../../app/assets/img/kev-owl2-sm.jpg";
import garlandimg from "../../app/assets/img/garland50.jpg";
import archimg from "../../app/assets/img/archpinkblue-sq.jpg";
import bikeimg from "../../app/assets/img/kev-bike-sq.png";
import { validateArchForm } from "../archForm/validateArchForm";
import TermsAndConditions from "../twistingform/termsAndConditions";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import FAQColumnStyle from "../../pages/faqColumnStyle";

const ColumnForm = () => {
  const [currentCard, setCurrentCard] = useState(1);
  const [formData, setFormData] = useState({
    date: "",
    time: "",
    duration: "",
    address: "",
    address2: "",
    addressName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    instagram: "",
    archNum: 0,
    archSize: "",
    archImage: "",
    archStyle: "",
    archColor0: "#ff0000",
    archColor1: "#ffa500",
    archColor2: "#ffff00",
    archColor3: "#008000",
    archColor4: "#0000ff",
    archColor5: "#4b0082",
    garlandNum: 0,
    garlandSize: "",
    garlandImage: "",
    garlandColor0: "#ff0000",
    garlandColor1: "#ffa500",
    garlandColor2: "#ffff00",
    garlandColor3: "#008000",
    garlandColor4: "#0000ff",
    garlandColor5: "#4b0082",
    columnNum: 0,
    columnSize: "",
    columnImage: "",
    columnImage2: "",
    columnImage3: "",
    columnStyle: "",
    columnColor0: "#ff0000",
    columnColor1: "#ffa500",
    columnColor2: "#ffff00",
    columnColor3: "#008000",
    columnColor4: "#0000ff",
    columnColor5: "#4b0082",
    centerpieceNum: 0,
    centerpieceDescription: "",
    centerpieceImage: "",
    eventType: "",
    eventSetting: "",
    numGuests: 0,
    ageRange: "",
    theme: "",
    parking: "",
    referal: "",
    additionalNotes: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [showTCModal, setShowTCModal] = useState(false);
  const [numColumnColors, setNumColumnColors] = useState("");
  const [showInput2, setShowInput2] = useState(false);
  const [showInput3, setShowInput3] = useState(false);

  const [twistingModal, setTwistingModal] = useState(false);
  const [displayTwisting, setDisplayTwisting] = useState(false);

  const [garlandModal, setGarlandModal] = useState(false);
  const [displayGarland, setDisplayGarland] = useState(false);
  const [numGarlandColors, setNumGarlandColors] = useState("");

  const [archModal, setArchModal] = useState(false);
  const [displayArch, setDisplayArch] = useState(false);
  const [numArchColors, setNumArchColors] = useState("");

  const [centerpieceModal, setCenterpieceModal] = useState(false);
  const [displayCenterpiece, setDisplayCenterpiece] = useState(false);

  const handleToggle = () => {
    setTermsAndConditions(!termsAndConditions);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleNumColumnColorsChange = (e) => {
    setNumColumnColors(e.target.value);
  };

  const navigate = useNavigate();

  // TWISTING MODAL
  const handleTwistingModalOpen = () => {
    setTwistingModal(true);
  };
  const handleTwistingModalClose = () => {
    setTwistingModal(false);
  };
  const twistingModalSubmit = () => {
    setDisplayTwisting(true);
    handleTwistingModalClose();
  };

  // GARLAND MODAL
  const handleGarlandModalOpen = () => {
    setGarlandModal(true);
  };
  const handleGarlandModalClose = () => {
    setGarlandModal(false);
  };
  const garlandModalSubmit = () => {
    setDisplayGarland(true);
    handleGarlandModalClose();
  };
  const handleNumGarlandColorsChange = (e) => {
    setNumGarlandColors(e.target.value);
  };

  // ARCH MODAL
  const handleArchModalOpen = () => {
    setArchModal(true);
  };
  const handleArchModalClose = () => {
    setArchModal(false);
  };
  const archModalSubmit = () => {
    setDisplayArch(true);
    handleArchModalClose();
  };
  const handleNumArchColorsChange = (e) => {
    setNumArchColors(e.target.value);
  };

  // CENTERPIECE MODAL
  const handleCenterpieceModalOpen = () => {
    setCenterpieceModal(true);
  };
  const handleCenterpieceModalClose = () => {
    setCenterpieceModal(false);
  };
  const centerpieceModalSubmit = () => {
    setDisplayCenterpiece(true);
    handleCenterpieceModalClose();
  };

  const handleNextCard = () => {
    setCurrentCard((prevCard) => prevCard + 1);
  };

  const handlePreviousCard = () => {
    setCurrentCard((prevCard) => prevCard - 1);
  };

  const handleColumnFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const timestamp = new Date().getTime();
      const id = `${timestamp}_${formData.firstName}`
      const docRef = await addDoc(collection(db, "ColumnFormCollection"), {
        "AA": id,
        "AA Name First": formData.firstName,
        "AA Name Last": formData.lastName,
        "AA Phone": formData.phone,
        "AA Email": formData.email,
        "AA Instagram": formData.instagram,
        "Terms & Conditions": termsAndConditions,
        "AA Date": formData.date,
        "AA Time": formData.time,
        "Address1": formData.address,
        "Address2": formData.address2,

        "Additional Notes": formData.additionalNotes,
        "Event Theme": formData.theme,
        "Location Type": formData.eventSetting,
        "Parking": formData.parking,
        "Referal": formData.referal,

        "Columns Number": formData.columnNum,
        "Columns Size": formData.columnSize,
        "Columns Style": formData.columnStyle,
        "Columns Number of Colors": numColumnColors,
        "Column Color 1": formData.columnColor0,
        "Column Color 2": formData.columnColor1,
        "Column Color 3": formData.columnColor2,
        "Column Color 4": formData.columnColor3,
        "Column Color 5": formData.columnColor4,
        "Column Color 6": formData.columnColor5,
        "Column Example 1": formData.columnImage,
        "Column Example 2": formData.columnImage2,
        "Column Example 3": formData.columnImage3,

        "Centerpiece Number": formData.centerpieceNum,
        "Centerpiece Description": formData.centerpieceDescription,
        "Centerpiece Example 1": formData.centerpieceImage,

        "Twisting Duration": formData.duration,
        "Twisting Event Type": formData.eventType,
        "Twisting Number of Guests": formData.numGuests,
        "Twisting Age Range": formData.ageRange,

        "Arch Number": formData.archNum,
        "Arch Number of Colors": numArchColors,
        "Arch Color 1": formData.archColor0,
        "Arch Color 2": formData.archColor1,
        "Arch Color 3": formData.archColor2,
        "Arch Color 4": formData.archColor3,
        "Arch Color 5": formData.archColor4,
        "Arch Color 6": formData.archColor5,
        "Arch Size": formData.archSize,
        "Arch Style": formData.archStyle,
        "Arch Example 1": formData.archImage,

        "Garland Number": formData.garlandNum,
        "Garland Size": formData.garlandSize,
        "Garland Number of Colors": numGarlandColors,
        "Garland Color 1": formData.garlandColor0,
        "Garland Color 2": formData.garlandColor1,
        "Garland Color 3": formData.garlandColor2,
        "Garland Color 4": formData.garlandColor3,
        "Garland Color 5": formData.garlandColor4,
        "Garland Color 6": formData.garlandColor5,
        "Garland Example": formData.garlandImage,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    const { isValid, errors } = validateArchForm(formData);
    setFormErrors(errors);
    setIsFormValid(isValid);

    if (isValid) {
      navigate("/thankyou");
    }
  };

  const renderCard = (cardNumber) => {
    switch (cardNumber) {
      case 1: //Design
        return (
          <div className="form-div">
            <h2 className="twisting-h2">Customize your Column(s)</h2>
            <label className="twisting-label">
              {" "}
              <span className="required">*</span>How many Columns do you need?
            </label>
            <select
              className="form-control "
              value={formData.columnNum}
              id="columnNum"
              name="columnNum"
              onChange={handleInputChange}
            >
              <option value=""></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>

          

            <label className="twisting-label">
              <span className="required">*</span>How many colors do you want for
              your Column(s)
            </label>
            <select
              name="numColumnColors"
              id="numColumnColors"
              className="form-control"
              onChange={handleNumColumnColorsChange}
              value={numColumnColors}
            >
              <option value=""></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
            <p
              className="text-dark"
              id="pickerTitle"
              style={{ display: numColumnColors >= 1 ? "block" : "none" }}
            >
              Click the colors below to select which color(s) you want:
            </p>
            <div className="color-row">
              <input
                type="color"
                id="columnColor0"
                name="columnColor0"
                value={formData.columnColor0}
                onChange={handleInputChange}
                className="col form-control colorPicker"
                style={{ display: numColumnColors >= 1 ? "block" : "none" }}
              />
              <input
                type="color"
                id="columnColor1"
                name="columnColor1"
                value={formData.columnColor1}
                onChange={handleInputChange}
                className="col form-control colorPicker"
                style={{ display: numColumnColors >= 2 ? "block" : "none" }}
              />
              <input
                type="color"
                id="columnColor2"
                name="columnColor2"
                value={formData.columnColor2}
                className="col form-control colorPicker"
                onChange={handleInputChange}
                style={{ display: numColumnColors >= 3 ? "block" : "none" }}
              />
              <input
                type="color"
                id="columnColor3"
                name="columnColor3"
                value={formData.columnColor3}
                onChange={handleInputChange}
                className="col form-control colorPicker"
                style={{ display: numColumnColors >= 4 ? "block" : "none" }}
              />
              <input
                type="color"
                id="columnColor4"
                name="columnColor4"
                value={formData.columnColor4}
                onChange={handleInputChange}
                className="col form-control colorPicker"
                style={{ display: numColumnColors >= 5 ? "block" : "none" }}
              />
              <input
                type="color"
                id="columnColor5"
                name="columnColor5"
                value={formData.columnColor5}
                className="col form-control colorPicker"
                onChange={handleInputChange}
                style={{ display: numColumnColors >= 6 ? "block" : "none" }}
              />
            </div>
            <label className="twisting-label">
              <span className="required">*</span>What Size Would You Like?
            </label>
            <select
              name="columnSize"
              value={formData.columnSize}
              id="columnSize"
              className="form-control "
              onChange={handleInputChange}
            >
              <option value=""></option>
              <option value="4ft">4ft Balloon Column</option>
              <option value="6ft">6ft Balloon Column</option>
              <option value="8ft">8ft Balloon Column</option>
              <option value="10ft">10ft Balloon Column</option>
            </select>
          </div>
        );

      case 2: //Design
        return (
          <div className="form-div">
            <h2 className="twisting-h2">Customize your Column(s)</h2>

          

            <div className="twisting-form-section">
              <label className="twisting-label">
                <span className="required">*</span>Paste 1-3 links to photos
                similar to what you want
              </label>
              <div className="twisting-form-div">
                <input
                  type="text"
                  name="columnImage"
                  value={formData.columnImage}
                  className="form-control"
                  id="columnImage"
                  onChange={handleInputChange}
                />
                <button
                  className="quote-add-another-button"
                  onClick={() => setShowInput2(true)}
                  style={{ display: showInput2 ? "none" : "inline" }}
                >
                  add another
                </button>
              </div>
              {showInput2 && (
                <div className="twisting-form-div">
                  <input
                    type="text"
                    name="columnImage2"
                    value={formData.columnImage2}
                    className="form-control"
                    onChange={handleInputChange}
                  />
                  <button
                    className="quote-add-another-button"
                    onClick={() => setShowInput3(true)}
                    style={{ display: showInput3 ? "none" : "inline" }}
                  >
                    add another
                  </button>
                </div>
              )}
              {showInput3 && (
                <div className="twisting-form-div">
                  <input
                    type="text"
                    name="columnImage3"
                    value={formData.columnImage3}
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              )}
            </div>
            <label className="twisting-label">
              <span className="required">*</span>Which Column Style do you want?{" "}
              
            </label>
            <select
              name="columnStyle"
              value={formData.columnStyle}
              id="columnStyle"
              className="form-control "
              onChange={handleInputChange}
            >
              <option value=""></option>
              <option value="Quad">Quad</option>
              <option value="Organic">Organic</option>
            </select>
            <FAQColumnStyle/>
          </div>
        );
      case 3: //When & Where
        return (
          <div className="form-div">
            <h2 className="twisting-h2">
              When & Where do you need your Column(s)?
            </h2>
            <label className="twisting-label">
              <span className="required">*</span>Date:
            </label>
            <input
              type="date"
              name="date"
              className="form-control"
              value={formData.date}
              onChange={handleInputChange}
            />
            <label className="twisting-label">
              <span className="required">*</span>Time:
            </label>
            <input
              type="time"
              name="time"
              className="form-control"
              value={formData.time}
              onChange={handleInputChange}
            />
            <label className="twisting-label">
              <span className="required">*</span>Event Address
            </label>
            <input
              type="text"
              name="address"
              className="form-control"
              value={formData.address}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="address2"
              className="form-control"
              value={formData.address2}
              onChange={handleInputChange}
            />
            <p></p>
            <select
              name="eventSetting"
              className="form-control twisting-select"
              value={formData.eventSetting}
              onChange={handleInputChange}
            >
              <option value="">-- Where will we set up your columns? --</option>
              <option value="House">House (indoor)</option>
              <option value="Backyard">Backyard </option>
              <option value="Apartment">Apartment</option>
              <option value="Event Venue">Event Venue</option>
              <option value="Festival Grounds">Festival Grounds</option>
              <option value="Park">Public Park</option>
              <option value="Pool">Pool</option>
              <option value="School">School</option>
              <option value="Daycare">Daycare</option>
              <option value="Spark/Parklab">
                Spark Social SF/Parklab Garden
              </option>
              <option value="Other">Other</option>
            </select>
          </div>
        );
      case 4: //personal info
        return (
          <div className="form-div">
            <h2 className="twisting-h2">Your Info</h2>
            <label className="twisting-label">
              <span className="required">*</span>First Name
            </label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <label className="twisting-label">
              <span className="required">*</span>Last Name
            </label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <label className="twisting-label">
              <span className="required">*</span>Email
            </label>
            <input
              type="text"
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleInputChange}
            />
            <label className="twisting-label">
              <span className="required">*</span>Phone Number
            </label>
            <input
              type="text"
              name="phone"
              className="form-control"
              value={formData.phone}
              onChange={handleInputChange}
            />
            <label className="twisting-label">Instagram Handle</label>
            <input
              type="text"
              name="instagram"
              className="form-control"
              value={formData.instagram}
              placeholder="If you prefer Instagram messaging"
              onChange={handleInputChange}
            />
          </div>
        );
      case 5: //add-ons
        return (
          <div className="form-div">
            <h2 className="twisting-h2">Add-ons</h2>
            <p className="twisting-p">
              If you want more high-quality decor, or Kevin's World-Famous
              Balloon Art, simply add it to your order!
            </p>
            <div>
              <div className="twisting-form-row">
                <div className="add-on-container">
                  <img src={owlimg} alt="" className="twisting-form-img" />
                  <div className="add-on-overlay">
                    <p>Balloon Twisting</p>
                    <div className="add-on-button-container">
                      <button
                        className="add-on-learn-more"
                        onClick={() => {
                          window.open("/book", "_blank");
                        }}
                      >
                        Learn More
                      </button>
                      <button
                        className="add-on-book-this"
                        onClick={handleTwistingModalOpen}
                      >
                        Book This!
                      </button>
                    </div>
                  </div>
                </div>
                <div className="add-on-container">
                  <img src={archimg} alt="" className="twisting-form-img" />
                  <div className="add-on-overlay">
                    <p>Balloon Arches</p>
                    <div className="add-on-button-container">
                      <button
                        className="add-on-learn-more"
                        onClick={() => {
                          window.open("/arches", "_blank");
                        }}
                      >
                        Learn More
                      </button>
                      <button
                        className="add-on-book-this"
                        onClick={handleArchModalOpen}
                      >
                        Book This!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="twisting-form-row">
              <div className="add-on-container">
                <img src={garlandimg} alt="" className="twisting-form-img" />
                <div className="add-on-overlay">
                  <p>Balloon Garlands</p>
                  <div className="add-on-button-container">
                    <button
                      className="add-on-learn-more"
                      onClick={() => {
                        window.open("/garlands", "_blank");
                      }}
                    >
                      Learn More
                    </button>
                    <button
                      className="add-on-book-this"
                      onClick={handleGarlandModalOpen}
                    >
                      Book This!
                    </button>
                  </div>
                </div>
              </div>
              <div className="add-on-container">
                <img src={bikeimg} alt="" className="twisting-form-img" />
                <div className="add-on-overlay">
                  <p>Centerpieces</p>
                  <div className="add-on-button-container">
                    <button
                      className="add-on-learn-more"
                      onClick={() => {
                        window.open("/centerpieces", "_blank");
                      }}
                    >
                      Learn More
                    </button>
                    <button
                      className="add-on-book-this"
                      onClick={handleCenterpieceModalOpen}
                    >
                      Book This!
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* TWISTING MODAL*/}
            <Modal isOpen={twistingModal}>
              <ModalHeader toggle={handleTwistingModalClose}>
                Balloon Twisting
              </ModalHeader>
              <ModalBody>
                <div>
                  <div className="form-div">
                    <h2 className="twisting-h2">How long do you need Kevin?</h2>
                    <label className="twisting-label">
                      <span className="required">*</span>Select number of hours:
                    </label>
                    <select
                      name="duration"
                      className="form-control twisting-select"
                      value={formData.duration}
                      onChange={handleInputChange}
                    >
                      <option value="">-- Select an option --</option>
                      <option value="1hr">1 hour ----- $265</option>
                      <option value="1.5hr">1.5 hours -- $340</option>
                      <option value="2hr">2 hours ---- $415</option>
                      <option value="2.5hr">2.5 hours -- $490</option>
                      <option value="3hr">3 hours ---- $565</option>
                      <option value="3.5hr">3.5 hours -- $640</option>
                      <option value="3.5hr +">Over 3.5 hours </option>
                    </select>
                    <label className="twisting-label">Event Type</label>
                    <select
                      name="eventType"
                      className="form-control twisting-select"
                      value={formData.eventType}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        -- What kind of event is this? --
                      </option>
                      <option value="Corporate Event">Corporate Event</option>
                      <option value="Birthday">Birthday Party</option>
                      <option value="Wedding">Wedding</option>
                      <option value="Holiday">Holiday Event</option>
                      <option value="Bachelorette/Bachelor Party">
                        Bachelorette/Bachelor Party
                      </option>
                      <option value="Graduation Party">Graduation</option>
                      <option value="Baby Shower">Baby Shower</option>
                      <option value="Festival">Festival</option>
                      <option value="Carnival">Carnival</option>
                      <option value="Other">Other</option>
                    </select>
                    <label className="twisting-label">
                      Approximate Number of Guests
                    </label>
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                      className="numguests-range"
                    >
                      <input
                        type="range"
                        min="0"
                        max="100"
                        id="numGuests"
                        value={formData.numGuests}
                        name="numGuests"
                        className="field"
                        onChange={handleInputChange}
                        style={{ width: "300px" }}
                      />{" "}
                      <span className="range-display">
                        {formData.numGuests}
                      </span>
                    </div>
                    <input
                      type="text"
                      name="ageRange"
                      className="form-control"
                      value={formData.ageRange}
                      placeholder="Age Range of Guests (ex: 3-7)"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </ModalBody>
              <button className="close-button" onClick={twistingModalSubmit}>
                Submit
              </button>
            </Modal>

            {/* GARLAND MODAL */}
            <Modal isOpen={garlandModal}>
              <ModalHeader toggle={handleGarlandModalClose}>
                Balloon Garlands
              </ModalHeader>
              <ModalBody>
                <label className="twisting-label">
                  {" "}
                  How many Garlands do you need?
                </label>
                <select
                  className="form-control "
                  value={formData.garlandNum}
                  id="garlandNum"
                  name="garlandNum"
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>

                <label className="twisting-label">
                  Paste a link to an garland similar to what you want
                </label>
                <input
                  type="text"
                  name="garlandImage"
                  value={formData.garlandImage}
                  className="form-control"
                  id="garlandImage"
                  onChange={handleInputChange}
                />

                <label className="twisting-label">
                  What Size Would You Like?
                </label>
                <select
                  name="garlandSize"
                  value={formData.garlandSize}
                  id="garlandSize"
                  className="form-control "
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="6ft">6ft Balloon garland</option>
                  <option value="12ft">12ft Balloon garland</option>
                  <option value="20ft">20ft Balloon garland</option>
                  <option value="30ft">30ft Balloon garland</option>
                </select>

                <label className="twisting-label">
                  How many colors do you want for your garland(s)
                </label>
                <select
                  name="numGarlandColors"
                  id="numGarlandColors"
                  className="form-control"
                  onChange={handleNumGarlandColorsChange}
                  value={numGarlandColors}
                >
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
                <p
                  className="text-dark"
                  id="pickerTitle"
                  style={{ display: numGarlandColors >= 1 ? "block" : "none" }}
                >
                  Click the colors to choose exactly what color(s) you want...
                </p>
                <div className="color-row">
                  <input
                    type="color"
                    id="garlandColor0"
                    name="garlandColor0"
                    value={formData.garlandColor0}
                    onChange={handleInputChange}
                    className="col form-control colorPicker"
                    style={{
                      display: numGarlandColors >= 1 ? "block" : "none",
                    }}
                  />
                  <input
                    type="color"
                    id="garlandColor1"
                    name="garlandColor1"
                    value={formData.garlandColor1}
                    onChange={handleInputChange}
                    className="col form-control colorPicker"
                    style={{
                      display: numGarlandColors >= 2 ? "block" : "none",
                    }}
                  />
                  <input
                    type="color"
                    id="garlandColor2"
                    name="garlandColor2"
                    value={formData.garlandColor2}
                    className="col form-control colorPicker"
                    onChange={handleInputChange}
                    style={{
                      display: numGarlandColors >= 3 ? "block" : "none",
                    }}
                  />
                  <input
                    type="color"
                    id="garlandColor3"
                    name="garlandColor3"
                    value={formData.garlandColor3}
                    onChange={handleInputChange}
                    className="col form-control colorPicker"
                    style={{
                      display: numGarlandColors >= 4 ? "block" : "none",
                    }}
                  />
                  <input
                    type="color"
                    id="garlandColor4"
                    name="garlandColor4"
                    value={formData.garlandColor4}
                    onChange={handleInputChange}
                    className="col form-control colorPicker"
                    style={{
                      display: numGarlandColors >= 5 ? "block" : "none",
                    }}
                  />
                  <input
                    type="color"
                    id="garlandColor5"
                    name="garlandColor5"
                    value={formData.garlandColor5}
                    className="col form-control colorPicker"
                    onChange={handleInputChange}
                    style={{
                      display: numGarlandColors >= 6 ? "block" : "none",
                    }}
                  />
                </div>
              </ModalBody>
              <button className="close-button" onClick={garlandModalSubmit}>
                Submit
              </button>
            </Modal>

            {/* ARCH MODAL */}
            <Modal isOpen={archModal}>
              <ModalHeader toggle={handleArchModalClose}>
                Balloon Arches
              </ModalHeader>
              <ModalBody>
                <label className="twisting-label">
                  {" "}
                  How many Arches do you need?
                </label>
                <select
                  className="form-control "
                  value={formData.archNum}
                  id="archNum"
                  name="archNum"
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>

                <label className="twisting-label">
                  Paste a link to an arch similar to what you want
                </label>
                <input
                  type="text"
                  name="archImage"
                  value={formData.archImage}
                  className="form-control"
                  id="archImage"
                  onChange={handleInputChange}
                />

                <label className="twisting-label">
                  What Size Would You Like?
                </label>
                <select
                  name="archSize"
                  value={formData.archSize}
                  id="archSize"
                  className="form-control "
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="12ft">12ft Balloon Arch</option>
                  <option value="20ft">20ft Balloon Arch</option>
                  <option value="30ft">30ft Balloon Arch</option>
                  <option value="40ft">40ft Balloon Arch</option>
                </select>

                <label className="twisting-label">
                  Which arch style do you want?{" "}
                  <span
                    onClick={() => {
                      window.open("/arches", "_blank");
                    }}
                    className="twisting-span"
                  >
                    Learn more
                  </span>
                </label>
                <select
                  name="archStyle"
                  value={formData.archStyle}
                  id="archStyle"
                  className="form-control "
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="Quad">Quad</option>
                  <option value="Organic">Organic</option>
                  <option value="Helium">Helium</option>
                </select>
                <label className="twisting-label">
                  How many colors do you want for your arch(es)
                </label>
                <select
                  name="numArchColors"
                  id="numArchColors"
                  className="form-control"
                  onChange={handleNumArchColorsChange}
                  value={numArchColors}
                >
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
                <p
                  className="text-dark"
                  id="pickerTitle"
                  style={{ display: numArchColors >= 1 ? "block" : "none" }}
                >
                  Click the colors to choose exactly what color(s) you want...
                </p>
                <div className="color-row">
                  <input
                    type="color"
                    id="archColor0"
                    name="archColor0"
                    value={formData.archColor0}
                    onChange={handleInputChange}
                    className="col form-control colorPicker"
                    style={{ display: numArchColors >= 1 ? "block" : "none" }}
                  />
                  <input
                    type="color"
                    id="archColor1"
                    name="archColor1"
                    value={formData.archColor1}
                    onChange={handleInputChange}
                    className="col form-control colorPicker"
                    style={{ display: numArchColors >= 2 ? "block" : "none" }}
                  />
                  <input
                    type="color"
                    id="archColor2"
                    name="archColor2"
                    value={formData.archColor2}
                    className="col form-control colorPicker"
                    onChange={handleInputChange}
                    style={{ display: numArchColors >= 3 ? "block" : "none" }}
                  />
                  <input
                    type="color"
                    id="archColor3"
                    name="archColor3"
                    value={formData.archColor3}
                    onChange={handleInputChange}
                    className="col form-control colorPicker"
                    style={{ display: numArchColors >= 4 ? "block" : "none" }}
                  />
                  <input
                    type="color"
                    id="archColor4"
                    name="archColor4"
                    value={formData.archColor4}
                    onChange={handleInputChange}
                    className="col form-control colorPicker"
                    style={{ display: numArchColors >= 5 ? "block" : "none" }}
                  />
                  <input
                    type="color"
                    id="archColor5"
                    name="archColor5"
                    value={formData.archColor5}
                    className="col form-control colorPicker"
                    onChange={handleInputChange}
                    style={{ display: numArchColors >= 6 ? "block" : "none" }}
                  />
                </div>
              </ModalBody>
              <button className="close-button" onClick={archModalSubmit}>
                Submit
              </button>
            </Modal>

            {/* CENTERPIECE MODAL */}
            <Modal isOpen={centerpieceModal}>
              <ModalHeader toggle={handleCenterpieceModalClose}>
                Custom Centerpieces
              </ModalHeader>
              <ModalBody>
                <label className="twisting-label">
                  {" "}
                  How many Centerpieces do you need?
                </label>
                <select
                  className="form-control "
                  value={formData.centerpieceNum}
                  id="centerpieceNum"
                  name="centerpieceNum"
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
                <label className="twisting-label">
                  Describe what you want for your Centerpiece(s)
                </label>
                <input
                  type="textarea"
                  name="centerpieceDescription"
                  value={formData.centerpieceDescription}
                  className="form-control"
                  id="centerpieceDescription"
                  onChange={handleInputChange}
                />
                <label className="twisting-label">
                  Paste a link to an Centerpiece similar to what you want, if
                  possible
                </label>
                <input
                  type="text"
                  name="centerpieceImage"
                  value={formData.centerpieceImage}
                  className="form-control"
                  id="centerpieceImage"
                  onChange={handleInputChange}
                />
              </ModalBody>
              <button className="close-button" onClick={centerpieceModalSubmit}>
                Submit
              </button>
            </Modal>
          </div>
        );

      case 6: //additional info
        return (
          <div className="additional-info-div">
            <h2 className="twisting-h2">Additional Information</h2>
            <label className="twisting-label">
              Is there a theme for your event?
            </label>
            <input
              type="text"
              name="theme"
              className="form-control"
              value={formData.theme}
              onChange={handleInputChange}
            />
            <label className="twisting-label">Where can Kevin park? </label>
            <input
              type="text"
              name="parking"
              className="form-control"
              value={formData.parking}
              onChange={handleInputChange}
            />
            <label className="twisting-label">
              How did you hear about us?{" "}
            </label>
            <input
              type="text"
              name="referal"
              className="form-control"
              value={formData.referal}
              onChange={handleInputChange}
            />
            <label className="twisting-label">Any additional notes? </label>
            <input
              type="textarea"
              name="additionalNotes"
              className="form-control"
              value={formData.additionalNotes}
              onChange={handleInputChange}
            />
          </div>
        );
      case 7: //info display
        return (
          <div className="form-div">
            <label className="twisting-label">Your Event:</label>{" "}
            <p className="twisting-p">
              *please check the info is correct before you submit*
            </p>
            <p className="twisting-p">
              <span className="twisting-span">Your Column Order:</span>{" "}
            </p>
            <div className="info-display">
              <p className="twisting-p">
                <span className="twisting-span">Number of Columns:</span>{" "}
                {formData.columnNum}
              </p>
              <p className="twisting-p">
                <span className="twisting-span">Column Examples:</span>{" "}
                {formData.columnImage.slice(0,30)}..., {formData.columnImage2.slice(0,30)}...,{" "}
                {formData.columnImage3.slice(0,30)}...
              </p>
              <p className="twisting-p">
                <span className="twisting-span">Column Size:</span>{" "}
                {formData.columnSize}
              </p>
              <p className="twisting-p">
                <span className="twisting-span">Column Style:</span>{" "}
                {formData.columnStyle}
              </p>
              <p className="twisting-p">
                <span className="twisting-span">
                  Column Colors (in HEX values):
                </span>{" "}
                <p
                  className="twisting-p"
                  style={{
                    display: numColumnColors >= 1 ? "inline" : "none",
                  }}
                >
                  {formData.columnColor0},{" "}
                </p>
                <p
                  className="twisting-p"
                  style={{
                    display: numColumnColors >= 2 ? "inline" : "none",
                  }}
                >
                  {formData.columnColor1},{" "}
                </p>
                <p
                  className="twisting-p"
                  style={{
                    display: numColumnColors >= 3 ? "inline" : "none",
                  }}
                >
                  {formData.columnColor2},{" "}
                </p>{" "}
                <p
                  className="twisting-p"
                  style={{
                    display: numColumnColors >= 4 ? "inline" : "none",
                  }}
                >
                  {formData.columnColor3},{" "}
                </p>
                <p
                  className="twisting-p"
                  style={{
                    display: numColumnColors >= 5 ? "inline" : "none",
                  }}
                >
                  {formData.columnColor4},{" "}
                </p>
                <p
                  className="twisting-p"
                  style={{
                    display: numColumnColors >= 6 ? "inline" : "none",
                  }}
                >
                  {formData.columnColor5}
                </p>
              </p>
              <p className="twisting-p">
                <span className="twisting-span">
                  <span className="required">*</span>Date:
                </span>{" "}
                {formData.date}
              </p>
              <p className="twisting-p">
                <span className="twisting-span">
                  <span className="required">*</span>Arrival Time:
                </span>{" "}
                {formData.time}
              </p>

              <p className="twisting-p">
                <span className="twisting-span">Location:</span>{" "}
                {formData.addressName} {formData.address} {formData.address2}
              </p>
              <p className="twisting-p">
                <span className="twisting-span">Event Setting:</span>{" "}
                {formData.eventSetting}
              </p>
            </div>
            <p className="twisting-p">
              <span className="twisting-span">
                <span className="required">*</span>Your Info:
              </span>{" "}
            </p>
            <div className="info-display">
              <p className="twisting-p">
                {formData.firstName} {formData.lastName}
              </p>
              <p className="twisting-p">{formData.email} </p>
              <p className="twisting-p">{formData.phone} </p>
              <p className="twisting-p">{formData.instagram} </p>
            </div>
            {displayTwisting && (
              <div>
                <p className="twisting-p">
                  <span className="twisting-span">
                    Balloon Twisting Performance:
                  </span>{" "}
                </p>
                <div className="info-display">
                  <p className="twisting-p">
                    <span className="twisting-span">How long:</span>{" "}
                    {formData.duration}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Event Type:</span>{" "}
                    {formData.eventType}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Number of Guests:</span>{" "}
                    {formData.numGuests}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Age Range of Guests:</span>{" "}
                    {formData.ageRange}
                  </p>
                </div>
              </div>
            )}
            {displayGarland && (
              <div>
                <p className="twisting-p">
                  <span className="twisting-span">Your Garland Order:</span>{" "}
                </p>
                <div className="info-display">
                  <p className="twisting-p">
                    <span className="twisting-span">Number of Garlands:</span>{" "}
                    {formData.garlandNum}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Garland Example:</span>{" "}
                    {formData.garlandImage.slice(0,30)}...
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Garland Size:</span>{" "}
                    {formData.garlandSize}
                  </p>

                  <p className="twisting-p">
                    <span className="twisting-span">
                      Garland Colors (in HEX values):
                    </span>{" "}
                    <p
                      className="twisting-p"
                      style={{
                        display: numGarlandColors >= 1 ? "inline" : "none",
                      }}
                    >
                      {formData.garlandColor0},{" "}
                    </p>
                    <p
                      className="twisting-p"
                      style={{
                        display: numGarlandColors >= 2 ? "inline" : "none",
                      }}
                    >
                      {formData.garlandColor1},{" "}
                    </p>
                    <p
                      className="twisting-p"
                      style={{
                        display: numGarlandColors >= 3 ? "inline" : "none",
                      }}
                    >
                      {formData.garlandColor2},{" "}
                    </p>{" "}
                    <p
                      className="twisting-p"
                      style={{
                        display: numGarlandColors >= 4 ? "inline" : "none",
                      }}
                    >
                      {formData.garlandColor3},{" "}
                    </p>
                    <p
                      className="twisting-p"
                      style={{
                        display: numGarlandColors >= 5 ? "inline" : "none",
                      }}
                    >
                      {formData.garlandColor4},{" "}
                    </p>
                    <p
                      className="twisting-p"
                      style={{
                        display: numGarlandColors >= 6 ? "inline" : "none",
                      }}
                    >
                      {formData.garlandColor5}
                    </p>
                  </p>
                </div>
              </div>
            )}
            {displayArch && (
              <div>
                <p className="twisting-p">
                  <span className="twisting-span">Your Arch Order:</span>{" "}
                </p>
                <div className="info-display">
                  <p className="twisting-p">
                    <span className="twisting-span">Number of Arches:</span>{" "}
                    {formData.archNum}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Arch Example:</span>{" "}
                    {formData.archImage.slice(0,30)}...
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Arch Size:</span>{" "}
                    {formData.archSize}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Arch Style:</span>{" "}
                    {formData.archStyle}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">
                      Arch Colors (in HEX values):
                    </span>{" "}
                    <p
                      className="twisting-p"
                      style={{
                        display: numArchColors >= 1 ? "inline" : "none",
                      }}
                    >
                      {formData.archColor0},{" "}
                    </p>
                    <p
                      className="twisting-p"
                      style={{
                        display: numArchColors >= 2 ? "inline" : "none",
                      }}
                    >
                      {formData.archColor1},{" "}
                    </p>
                    <p
                      className="twisting-p"
                      style={{
                        display: numArchColors >= 3 ? "inline" : "none",
                      }}
                    >
                      {formData.archColor2},{" "}
                    </p>{" "}
                    <p
                      className="twisting-p"
                      style={{
                        display: numArchColors >= 4 ? "inline" : "none",
                      }}
                    >
                      {formData.archColor3},{" "}
                    </p>
                    <p
                      className="twisting-p"
                      style={{
                        display: numArchColors >= 5 ? "inline" : "none",
                      }}
                    >
                      {formData.archColor4},{" "}
                    </p>
                    <p
                      className="twisting-p"
                      style={{
                        display: numArchColors >= 6 ? "inline" : "none",
                      }}
                    >
                      {formData.archColor5}
                    </p>
                  </p>
                </div>
              </div>
            )}
            {displayCenterpiece && (
              <div>
                <p className="twisting-p">
                  <span className="twisting-span">Your Centerpiece Order:</span>{" "}
                </p>
                <div className="info-display">
                  <p className="twisting-p">
                    <span className="twisting-span">
                      Number of Centerpieces:
                    </span>{" "}
                    {formData.centerpieceNum}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">
                      Description of your Centerpiece(s):
                    </span>{" "}
                    {formData.centerpieceDescription}
                  </p>
                  <p className="twisting-p">
                    <span className="twisting-span">Centerpiece Example:</span>{" "}
                    {formData.centerpieceImage.slice(0,30)}...
                  </p>
                </div>
              </div>
            )}
            <p className="twisting-p">
              <span className="twisting-span">Additional Info:</span>{" "}
            </p>
            <div className="info-display">
              <p className="twisting-p">
                <span className="twisting-span">Event Theme:</span>{" "}
                {formData.theme}
              </p>
              <p className="twisting-p">
                <span className="twisting-span">Parking:</span>{" "}
                {formData.parking}
              </p>
              <p className="twisting-p">
                <span className="twisting-span">Additional Notes:</span>{" "}
                {formData.additionalNotes}
              </p>
            </div>
            <div className="twisting-tc">
              <input
                type="checkbox"
                onClick={handleToggle}
                checked={termsAndConditions}
              ></input>
              <p>.</p>
              <label className="twisting-label">
                I agree to Kevin's{" "}
                <span className="tc-span" onClick={() => setShowTCModal(true)}>
                  Terms & Conditions
                </span>
              </label>
              <Modal isOpen={showTCModal}>
                <div className="modal-content">
                  <ModalHeader>
                    Kevin's Balloons Terms and Conditions
                  </ModalHeader>

                  <ModalBody>
                    <TermsAndConditions />
                  </ModalBody>
                  <button
                    className="close-button"
                    onClick={() => setShowTCModal(false)}
                  >
                    close
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container">
      <div className="card-container">
        {renderCard(currentCard)}
        <span className="required require">*Required</span>
        <div className="button-container">
          {currentCard > 1 && (
            <button className="form-nav-button" onClick={handlePreviousCard}>
              Previous
            </button>
          )}
          {currentCard < 7 ? (
            <button className="form-nav-button" onClick={handleNextCard}>
              Next
            </button>
          ) : termsAndConditions ? (
            <button
              className="twisting-submit-button"
              onClick={handleColumnFormSubmit}
            >
              Get a Quote within 24hrs
            </button>
          ) : (
            <button className="twisting-invalid-button">
              Get a Quote within 24hrs
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ColumnForm;
