import React from "react";
import kevleaning from "../app/assets/img/kev-transparent-background.png";
import instagram from "../app/assets/img/instagram.png";
import video from "../app/assets/video/balloonCompilation.mp4";
import { useNavigate } from "react-router-dom";
import arrowleft from "../app/assets/img/arrowleft.png";
import arrowright from "../app/assets/img/arrowright.png";

import "./componentcss/videoplayer.css";

const VideoPlayer = () => {
  const navigate = useNavigate();
  const handleBook = () => {
    navigate("/book");
  };

  return (
    <div className="videoplayer-container ">
      <div className="row phone-leaning-container">
        <div className="col phone-container">
          <div className="video-row">
            <img
              src={arrowleft}
              alt="left arrow indicating Kevin the Balloon artist"
              className="arrow-img"
            />
            <h5>See Kevin in action!</h5>
            <img
              src={arrowright}
              alt="right arrow indicating Kevin the balloon artist"
              className="arrow-img"
            />
          </div>
          <div className=" phone">
            <video className="video" width="214px" height="390px" controls>
              <source src={video} />
            </video>

            <div className="instagram-div ">
              <a
                className="text-white"
                href="https://www.tiktok.com/@hislifeoftravel"
                target="_blank"
              >
                As seen on TikTok
                <img
                  className="instagram-img"
                  src={instagram}
                  alt="kevins.balloons"
                />
              </a>
            </div>
            
          </div>
        </div>
        <div className="col leaning-img-container">
          <img
            className=" leaning-img"
            src={kevleaning}
            alt="Kevin is the San Francisco Bay Area's globally demanded balloon twister"
          />
        </div>
      </div>
      <div className="videoplayer-btn-container">
        <button
          className="decor-btn"
          onClick={() => {
            navigate("/services");
          }}
        >
          Order Decor
        </button>
        <button className="booking-btn" onClick={handleBook}>
          Book Kevin
        </button>{" "}
      </div>
    </div>
  );
};

export default VideoPlayer;
