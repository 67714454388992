export const TESTIMONIALS = [
    {
      "id": 1,
      "firstName": "Christyn",
      "lastName": "M",
      "rating": 5,
      "quote": "We have had Kevin at our son’s birthday party 2 years in a row. He is the kids favorite part of the party hands down! I have yet to see him stumped by any requests, and the parents are equally impressed by his designs. Everyone talks about his ability and creativity for weeks after! Best decision we made was having Kevin at our party, we recommend him without reservation."

    },
    {
      "id": 2,
      "firstName": "Debbie",
      "lastName": "C",
      "rating": 5,
      "quote": "Highly recommend. Kevin was great to work with and the kids enjoyed the balloon animals. Thank you!"

    },
    {
      "id": 12,
      "firstName": "Sofia",
      "lastName": "L",
      "rating": 5,
      "quote": "Kevin's Balloons made my daughter's quinceañera so special with beautiful balloon centerpieces and a stunning balloon arch. Thank you!"
    },
    {
      "id": 4,
      "firstName": "Aiden",
      "lastName": "C",
      "rating": 5,
      "quote": "Kevin is a balloon genius! He created an incredible balloon column for my son's graduation party that everyone talked about. Highly recommend!"
    },
    {
      "id": 5,
      "firstName": "Zoe",
      "lastName": "G",
      "rating": 5,
      "quote": "Kevin's Balloons is the best! He created a beautiful balloon wall for our wedding that was the perfect backdrop for our photos. Thank you Kevin!"
    },
    {
      "id": 6,
      "firstName": "Mo",
      "lastName": "W",
      "rating": 5,
      "quote": "Kevin is the balloon master! He created an amazing balloon sculpture for our company's trade show booth that attracted lots of attention. Thanks Kevin!"
    },
    {
      "id": 7,
      "firstName": "Ava",
      "lastName": "S",
      "rating": 5,
      "quote": "Kevin's Balloons is amazing! He created a beautiful balloon arch for my daughter's sweet 16 that was the highlight of the party. Highly recommend!"
    },
    {
      "id": 8,
      "firstName": "Gabriel",
      "lastName": "G",
      "rating": 5,
      "quote": "Kevin is a balloon artist! He created a stunning balloon column for our school's prom that everyone loved. Thank you Kevin!"
    },
    {
      "id": 9,
      "firstName": "Samantha",
      "lastName": "K",
      "rating": 5,
      "quote": "Kevin's Balloons made our company party unforgettable with amazing balloon centerpieces and a stunning balloon arch. Highly recommend!"
    },
    {
      "id": 10,
      "firstName": "Jaden",
      "lastName": "C",
      "rating": 5,
      "quote": "Kevin is the best! He created a beautiful balloon sculpture for my daughter's birthday party that everyone loved. Thank you Kevin!"
    },
    {
      "id": 11,
      "firstName": "Yuna",
      "lastName": "G",
      "rating": 5,
      "quote": "Kevin's Balloons made our baby shower so special with beautiful balloon centerpieces and a stunning balloon arch. Thank you Kevin!"
    },
    {
      "id": 3,
      "firstName": "Amena",
      "lastName": "N",
      "rating": 5,
      "quote": "I can’t say enough good things about Kevin and his amazing skills!! Kevin came to our annual block party that we host for over 100 of our neighbors along with their kids. He was able to make an amazing balloon for all the kids (over 60 kids!) and not to mention it was raining that day too! All with a positive attitude and a huge amount of patience with the kids!"
    },
   
]