import React from "react";
import Homepage from "./pages/Homepage";
import Servicespage from "./pages/Servicespage";
import Contactpage from "./pages/Contactpage";
import Bookingpage from "./pages/Bookingpage";
import NavigationBarLarge from "./components/NavigationBarLarge";
import ThankYouPage from "./pages/ThankYouPage";
import Gallerypage from "./pages/Gallerypage";
import Archespage from "./pages/Archespage";
import Centerpiecepage from "./pages/Centerpiecepage";
import Columnpage from "./pages/Columnpage";
import Garlandpage from "./pages/Garlandpage";
import TestimonialPage from "./pages/TestimonialPage";
import BusinessCardPage from "./pages/BusinessCard";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import "./App.css";

import QuickQuote from "./components/quickQuote/quickQuote";

function App() {
  return (
    <div className="App">
      <NavigationBarLarge className="navigationbar" sticky />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="services" element={<Servicespage />} />
        <Route path="contact" element={<Contactpage />} />
        <Route path="book" element={<Bookingpage />} />
        <Route path="thankyou" element={<ThankYouPage />} />
        <Route path="columns" element={<Columnpage />} />
        <Route path="arches" element={<Archespage />} />
        <Route path="centerpieces" element={<Centerpiecepage />} />
        <Route path="garlands" element={<Garlandpage />} />
        <Route path="gallery" element={<Gallerypage />} />
        <Route path="testimonial" element={<TestimonialPage />} />
        <Route path="businesscard" element={<BusinessCardPage />} />
        
        
        <Route path="quickQuote" element={<QuickQuote />} />
      
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
