export const validateTwistingForm = (values) => {
  const errors = {};
  let isValid = true;

  if (!values.date) {
    errors.date = "Required";
    isValid = false;
  }

  if (!values.time) {
    errors.time = "Required";
    isValid = false;
  }

  if (!values.duration) {
    errors.duration = "Required";
    isValid = false;
  }
  if (!values.firstName) {
    errors.firstName = "Required";
    isValid = false;
  }
  if (!values.lastName) {
    errors.lastName = "Required";
    isValid = false;
  }
  if (!values.phone) {
    errors.phone = "Required";
    isValid = false;
  }
  if (!values.email) {
    errors.email = "Required";
    isValid = false;
  }

  return {isValid, errors};
};
