import Header from "../components/Header";
import CenterpieceForm from "../components/centerpieceForm/centerpieceForm";
import "./pagescss/bookingpage.css";
import bike from "../app/assets/img/kev-bike-sq.png";
import car from "../app/assets/img/ballooncar.png";
import owl from "../app/assets/img/kev-owl2.jpg";
import skier from "../app/assets/img/centerpiece-skier.jpg";
import bouquet from "../app/assets/img/centerpiece-bouquet.jpg";
import leprechaun from "../app/assets/img/centerpiece-leprechaun.jpg";
import santa from "../app/assets/img/christmas-display.jpg";
import mermaid from "../app/assets/img/centerpiece-meramid.jpg";

const InfoCard = () => {
  return (
    <div className="service-card">
      <div>
        <p className="service-description ">
          Looking for a unique way to add excitement to your event decorations?{' '}
          <img
            alt="Giant balloon car centerpiece for an event"
            className="service-img service-img-float-left"
            src={car}
          />
          Kevin's stunning balloon centerpieces are the perfect solution!
          Whether you want full-sized vehicles or larger-than-life characters
          <img
            alt="Kevin can make the famous balloon bicycle from the Wedding Crashers movie"
            className="service-img service-img-float-right"
            src={leprechaun}
          />
          and animals, Kevin can create custom balloon designs that will leave
          your guests in awe! Centerpieces are ideal for milestone birthdays, Holiday Events, Grand Openings, and Mall Events!  
          
        </p>
      </div>
    </div>
  );
};

const Centerpiecepage = () => {
  return (
    <>
      <Header
        headline="Balloon Centerpiece"
        headshotDisplay="none"

        tagline="Order and Customize the perfect Centerpiece for your event"
        display="none"
      />
      <div className="full-width-div">
        <div className="side-gallery side-gallery-left">
          <img
            src={owl}
            className="side-gallery-img"
            alt="Kevin, San Francisco Bay Area's premier balloon artist, with an amazing giant owl balloon animal centerpiece"
          />
          <img
            src={skier}
            className="side-gallery-img"
            alt="Kevin's amazing, hyper-realistic giant frog balloon animal centerpiece"
          />
          <img
            src={leprechaun}
            className="side-gallery-img"
            alt="A pompom balloon centerpiece as decoration for an event"
          />
        </div>
        <div className="page-content">
          <InfoCard style={{ flex: 1 }} />
          <CenterpieceForm style={{ flex: 1 }} />
        </div>
        <div className="side-gallery">
          <img
            src={bouquet}
            className="side-gallery-img"
            alt="Kevin's balloon flower bouquet centerpiece"
          />
          <img
            src={santa}
            className="side-gallery-img"
            alt="Kevin's Santa on a sleigh with Rudolph giant balloon animal centerpiece"
          />
          <img
            src={mermaid}
            className="side-gallery-img"
            alt="Kevin's hyper-realistic rabbit balloon animal centerpiece"
          />
        </div>
      </div>
    </>
  );
};

export default Centerpiecepage;
