import Header from "../components/Header";
import ArchForm from "../components/archForm/archForm";
import "./pagescss/bookingpage.css";
import arch16 from "../app/assets/img/balloon-Backdrop-16-square.jpg";
import arches from "../app/assets/img/archrainbow.jpg";
import superheroArch from "../app/assets/img/arch-superhero-square.jpg";
import purple from "../app/assets/img/archpurple.jpg";

const InfoCard = () => {
  return (
    <div className="service-card">
      <div>
        <img
          src={superheroArch}
          alt="Kevin, the San Francisco Bay Area's globally demanded balloon artist"
          className="service-img service-img-float-right"
        />
        <p className="service-description ">
          A balloon arch is the most visually striking balloon decor, perfect for graduations, weddings, or any other event! 
           Ask Kevin to add some of his famous balloon twisting
          designs to make your arch one of a kind! A balloon arch is sure to
          impress your guests and make your party stand out.
        </p>
      </div>
    </div>
  );
};

const ArchesPage = () => {
  return (
    <>
      <Header
        headline="Balloon Arches"
        headshotDisplay="none"
        tagline="Order and Customize the perfect arch for your event"
        display="none"
      />
      <div className="full-width-div">
        <div className="side-gallery side-gallery-left">
          <img
            src={arches}
            className="side-gallery-img"
            alt="Rainbow balloon arch"
          />
          <img
            src={superheroArch}
            className="side-gallery-img"
            alt="graduation balloon arch"
          />
        </div>
        <div className="page-content">
          <InfoCard style={{ flex: 1 }} />
          <ArchForm style={{ flex: 1 }} />
        </div>
        <div className="side-gallery">
          <img
            src={purple}
            className="side-gallery-img"
            alt="purple princess balloon arch"
          />
          <img
            src={arch16}
            className="side-gallery-img"
            alt="sweet 16 balloon arch"
          />
        </div>
      </div>
    </>
  );
};

export default ArchesPage;
