import React, { useState } from "react";
// import "./twistingform.css";
import superheroArch from "../app/assets/img/arch-superhero-square.jpg";

const FAQArchSize = () => {
  const [showFAQArchSize, setShowFAQArchSize] = useState(false);

  const openFAQModal = () => {
    setShowFAQArchSize(true);
  };
  const closeFAQModal = () => {
    setShowFAQArchSize(false);
  };
  return (
    <div>
      {showFAQArchSize ? (
        <div className="faq-content">
          <p className="service-description">
            With arches, size matters. The sizes you see are the full length of
            the arch in a straight, flat line. But when it's set up, the ends of
            the arch will ideally be about 2/3rds of the length apart, and the
            arch will reach 1/3rd of that height.
          </p>
          <p className="service-description">
            So a 12ft arch is only about 4ft tall, so these are usually clipped
            onto tables as decoration.
          </p>
          <p className="service-description">
            <img
              src={superheroArch}
              alt=""
              className="service-img service-img-float-right"
            />
            20ft arches are the most common. They usually stand about 6ft tall,
            so most people can stand under them, like this Superhero Arch for a birthday. These are ideal for Grand
            Openings, Weddings, and House Parties. 
            </p>
            <p className="service-description">
            While 30ft and 40ft arches
            are for truly grand spectacles where you want to wow your guests,
            ideal for Graduations, Event Venues, Fairs, Festivals, and Mall
            events.
          </p>
          <button className="close-button" onClick={closeFAQModal}>
            close
          </button>
        </div>
      ) : (
        <p className="twisting-p" onClick={openFAQModal}>
          FAQ: How big should my Arch be?
        </p>
      )}
    </div>
  );
};

export default FAQArchSize;
