import "./componentcss/scrollinggallery.css";
import arch16 from "../app/assets/img/balloon-Backdrop-16-square.jpg";
import owl from "../app/assets/img/kev-owl2-sm.jpg"
import bouquet from '../app/assets/img/centerpiece-bouquet.jpg'
import rainbow2 from '../app/assets/img/column-rainbow2-square.jpg'
import centerpieceMermaid from '../app/assets/img/centerpiece-meramid.jpg'
import july4 from '../app/assets/img/garland-4thjuly.jpg'
import superheroArch from '../app/assets/img/arch-superhero-square.jpg'
import rainbow4 from '../app/assets/img/Column-rainbow4-square.jpg'
import flamingo from '../app/assets/img/flamingoColumns.jpg'
import leprechaun from '../app/assets/img/centerpiece-leprechaun.jpg'
import columns from '../app/assets/img/columns50.jpg'
import halloweenColumns from '../app/assets/img/balloon-columns-Halloween.jpg'
import ceilingGarlands from '../app/assets/img/balloon-Garlands-ceiling-square.jpg'
import kev2 from '../app/assets/img/kev-twisting2.jpg'
import santa from '../app/assets/img/christmas-display.jpg'



const ScrollingGallery = () => {
  return (
    <div className="scrolling-gallery-container">
      <div className="scrolling-gallery">
        <img src={halloweenColumns} alt="" className="initiate-scroll" />
        <img src={arch16} alt="adorable arch16 balloon animal" />
        <img src={columns} alt="Kevin can make balloon columns for your next event or party" />
        <img src={owl} alt="Kevin, the San Francisco Bay Area's premier balloon artist, can make amazing balloon animals like this giant owl balloon" />
        <img src={ceilingGarlands} alt="A monumental balloon garland decorating a casino night" />
        <img src={rainbow2} alt="An amazing balloon animal rainbow2, created by Kevin, the San Francisco Bay Area's premeir balloon artist" />
        <img src={centerpieceMermaid} alt="Kevin can make garlands for any occasion, like this centerpieceMermaid balloon garland for a 50th birthday" />
        <img src={kev2} alt="Get hyper-realistic balloon animals, like this rabbit balloon animal, as a balloon centerpiece for your next event or party" />
        <img src={superheroArch} alt="Make me a bicycle, clown! Yes, Kevin can make a balloon bicycle" />
        <img src={flamingo} alt="Get giant amazing balloon columns like these flamingo balloon columns" />
        <img src={rainbow4} alt="Finding Nemo fish balloon animal" />
        <img src={santa} alt="Get a giant balloon centerpiece for your next holiday party, like this Santa on a sleigh with Rudolph" />
        <img src={leprechaun} alt="leprechaun balloon centperpiece" />
        <img src={july4} alt="Balloon Decor for your next party or event" />
        <img src={bouquet} alt="Get a beautiful balloon bouquet as a centerpiece for your next event or just for that special someone" />

        <img src={halloweenColumns} alt="" />
        <img src={arch16} alt="adorable balloon arch16 balloon animal" />
        <img src={columns} alt="Kevin can make balloon columns for your next event or party" />
        <img src={owl} alt="Kevin, the San Francisco Bay Area's premier balloon artist, can make amazing balloon animals like this giant owl balloon" />
        <img src={ceilingGarlands} alt="A monumental balloon garland decorating a casino night" />
        <img src={rainbow2} alt="An amazing balloon animal rainbow2, created by Kevin, the San Francisco Bay Area's premeir balloon artist" />
        <img src={centerpieceMermaid} alt="Kevin can make garlands for any occasion, like this centerpieceMermaid balloon garland for a 50th birthday" />
        <img src={kev2} alt="hyper-realistic balloon animals" />
        <img src={superheroArch} alt="Make me a bicycle, clown! Yes, Kevin can make a balloon bicycle" />
        <img src={flamingo} alt="Get giant amazing balloon columns like these flamingo balloon columns" />
        <img src={rainbow4} alt="Finding Nemo fish balloon animal" />
        <img src={santa} alt="Get a giant centerpiece for your next holiday party, like this Santa on a sleigh with Rudolph" />
        <img src={leprechaun} alt="Kevin can build balloon displays for your event or party, like this mermaid tail balloon garland and column" />
        <img src={july4} alt="Balloon Decor for your next party or event" />
        <img src={bouquet} alt="Get a beautiful balloon bouquet as a centerpiece for your next event or just for that special someone" />



      </div>
    </div>
  );
};

export default ScrollingGallery;
