import Header from "../components/Header";
import GarlandForm from "../components/garlandForm/garlandForm";
import "./pagescss/bookingpage.css";
import ceilingGarlands from "../app/assets/img/balloon-Garlands-ceiling-square.jpg";
import flowerimg from "../app/assets/img/garland-flowered-sq.jpg";
import mermaidimage from "../app/assets/img/theme-mermaid-sq.jpg";
import july from "../app/assets/img/garland6ft.jpg";
import engagement from "../app/assets/img/garland-pink-engagement-sq.jpg";
import garland50 from "../app/assets/img/garlanddisplay-sq.jpg";
import garland75 from "../app/assets/img/garland75.jpg";
import outdoor from "../app/assets/img/garland12ft.jpg";

const InfoCard = () => {
  return (
    <div className="service-card">
      <div>
        <p className="service-description ">
          A balloon garland is a fun and creative way to add some pizzazz to
          your party decor! A balloon garland is a beautiful arrangement of
          balloons in different colors, sizes, and shapes, attached together to 
          <img
            src={ceilingGarlands}
            alt="champagne balloon garland for a 50th birthday"
            className="service-img service-img-float-left"
          />
          {' '} form a cloud-like garland that can decorate a wall, car, staircase,
          bar, door, or pretty much anything you can imagine! 
          <img 
            src={flowerimg}
            alt="pastel balloon garland for an event"
            className="service-img service-img-float-right"
          />
          {' '}Garlands are perfect for adding elegance to a bridal shower, wedding, Country Club event, or your restaurant, or creating a fun photo backdrop for a birthday or graduation!
          {" "}
        </p>
      </div>
    </div>
  );
};

const Garlandpage = () => {
  return (
    <>
      <Header
        headline="Balloon Garlands"
        headshotDisplay="none"

        tagline="Order and Customize the perfect Garland for your event"
        display="none"
      />
      <div className="full-width-div">
        <div className="side-gallery side-gallery-left">
          <img
            src={garland50}
            className="side-gallery-img"
            alt="balloon garland for a 50th birthday party event"
          />
          <img
            src={garland75}
            className="side-gallery-img"
            alt="balloon garland for a casino event"
          />
          <img
            src={outdoor}
            className="side-gallery-img"
            alt="pink balloon garland for an outdoor event"
          />
        </div>

        <div className="page-content">
          <InfoCard style={{ flex: 1 }} />
          <GarlandForm style={{ flex: 1 }} />
        </div>
        <div className="side-gallery">
          <img
            src={july}
            className="side-gallery-img"
            alt="4th of July balloon garland ring"
          />
          <img
            src={engagement}
            className="side-gallery-img"
            alt="pastel pink balloon garland indoor"
          />
          <img
            src={mermaidimage}
            className="side-gallery-img"
            alt="mermaid tail balloon garland"
          />
        </div>
      </div>
    </>
  );
};

export default Garlandpage;
