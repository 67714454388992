import Header from "../components/Header";
import ColumnForm from "../components/columnForm/columnForm";
import "./pagescss/bookingpage.css";
import rainbow2 from "../app/assets/img/column-rainbow2-square.jpg";
import columns50 from "../app/assets/img/columns50.jpg";
import mermaidimage from "../app/assets/img/theme-mermaid-sq.jpg";
import flamingo from "../app/assets/img/flamingoColumns.jpg";
import rainbow from "../app/assets/img/Column-rainbow4-square.jpg";
import halloweenColumns from "../app/assets/img/balloon-columns-Halloween.jpg";

const InfoCard = () => {
  return (
    <div className="service-card ">
      <div>
        <p className="service-description ">
          <img
            alt="rainbow balloon columns"
            className="service-img service-img-float-left"
            src={rainbow2}
          />
          Balloon columns are versatile and eye-catching balloon decor that can
          add a pop of fun and excitement to any party or event! Essentially,
          it's a towering structure made up of dozens of balloons that are
          <img
            alt="Balloon columns Kevin made for a 50th birthday party"
            className="service-img service-img-float-right"
            src={columns50}
            />
          attached to a central column, creating a stunning vertical display
          that's sure to impress your guests. Ideal for birthdays, graduations,
          and sporting event. 
            
        </p>
      </div>
    </div>
  );
};

const Columnpage = () => {
  return (
    <>
      <Header
        headline="Balloon Columns"
        headshotDisplay="none"

        tagline="Order and Customize the perfect columns for your event"
        display="none"
      />
      <div className="full-width-div">
        <div className="side-gallery side-gallery-left">
          <img
            src={flamingo}
            className="side-gallery-img"
            alt="two giant flamingo balloon columns Kevin made for a 10th birthday party"
          />
          <img
            src={rainbow2}
            className="side-gallery-img"
            alt="butterfly balloon columns"
          />
          <img
            src={mermaidimage}
            className="side-gallery-img"
            alt="mermaid tail balloon column"
          />
        </div>

        <div className="page-content">
          <InfoCard style={{ flex: 1 }} />
          <ColumnForm style={{ flex: 1 }} />
        </div>
        <div className="side-gallery">
          <img
            src={columns50}
            className="side-gallery-img"
            alt="Balloon columns Kevin made for a 50th birthday party"
          />
          <img
            src={rainbow}
            className="side-gallery-img"
            alt="a short, pink balloon column"
          />
          <img
            src={halloweenColumns}
            className="side-gallery-img"
            alt="Butterfly balloon columns"
          />
        </div>
      </div>
    </>
  );
};

export default Columnpage;
