import React, { useState } from "react";
// import "./twistingform.css";
import rainbow from "../app/assets/img/column-rainbow2-square.jpg";
import halloween from "../app/assets/img/balloon-columns-Halloween.jpg";
import organic from "../app/assets/img/columns50.jpg";

const FAQColumnStyle = () => {
  const [showFAQColumnStyle, setShowFAQColumnStyle] = useState(false);

  const openFAQModal = () => {
    setShowFAQColumnStyle(true);
  };
  const closeFAQModal = () => {
    setShowFAQColumnStyle(false);
  };
  return (
    <div>
      {showFAQColumnStyle ? (
        <div className="faq-content">
          <p className="service-description">
            <img
              src={rainbow}
              alt=""
              className="service-img service-img-float-left"
            />
            Quad Style Columns are built with balloons stacked regularly to
            create patterns with the colors, like the rainbow and Halloween
            columns,
            <img
              src={halloween}
              alt=""
              className="service-img service-img-float-right"
            />
            perfect for corporate events, sporting events, and graduations. They
            have a clean, crisp, professional look.
          </p>
          <p className="service-description">
            <img
              src={organic}
              alt=""
              className="service-img service-img-float-left"
            />
            Organic Style columns have about 2-3x more balloons than Quads. The
            balloons vary in size, creating a cloud-like, natural look. This has
            recently become the most popular style, since its so photogenic.{" "}
          </p>
          <p className="service-description"></p>
          <button className="close-button" onClick={closeFAQModal}>
            close
          </button>
        </div>
      ) : (
        <p className="twisting-p" onClick={openFAQModal}>
          FAQ: What does "Column Style" mean?
        </p>
      )}
    </div>
  );
};

export default FAQColumnStyle;
