import React, { useState } from "react";
import "./twistingform.css";

const FAQDuration = () => {
  const [showFAQDuration, setShowFAQDuration] = useState(false);

 
  const openFAQModalDuration = () => {
    setShowFAQDuration(true)
  }
  const closeFAQModalDuration = () =>{
    setShowFAQDuration(false)
  }
  return (
    <div>
      {showFAQDuration ? (
        // <div className="modal" onClick={closeFAQModalDuration}> 
          <div className="faq-content">
            <p className="twisting-p">
              It depends mostly on how many guests at your event will want balloons and how complex their requests will be. Since some people ask for simple swords while others ask for complex unicorns and superheroes, a good rule of thumb is book 7 minutes per guest.  
            </p>
            <button className="close-button" onClick={closeFAQModalDuration}>close</button>
          </div>
        //  </div>
      ) : (
        <p className="twisting-p" onClick={openFAQModalDuration}>
          FAQ: How much time should I book?
        </p>
      )}
    </div>
  );
};

export default FAQDuration;
