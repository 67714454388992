import React, { useState } from "react";
// import "./twistingform.css";
import quadarch from "../app/assets/img/quadarch.png";
import heliumarch from "../app/assets/img/heliumarch.png";
import superheroArch from "../app/assets/img/arch-superhero-square.jpg";

const FAQArch = () => {
  const [showFAQArch, setShowFAQArch] = useState(false);

 
  const openFAQModal = () => {
    setShowFAQArch(true)
  }
  const closeFAQModal = () =>{
    setShowFAQArch(false)
  }
  return (
    <div>
      {showFAQArch ? (
          <div className="faq-content">
          <p className="service-description">
        <img
          src={quadarch}
          alt=""
          className="service-img service-img-float-left"
        />
        Quad Style Arches are the classic balloon arch that you often see at
        corporate events and graduations. They have a clean, crisp, professional
        look.
      </p>
      <p className="service-description">
        <img
          src={superheroArch}
          alt=""
          className="service-img service-img-float-right"
        />
        Organic Style Arches have about 2-3x more balloons than Quad arches. The
        balloons vary in size, creating a cloud-like, natural look. This has recently
        become the most popular style, since its so photogenic.{" "}
      </p>
      <p className="service-description">
        <img
          src={heliumarch}
          alt=""
          className="service-img service-img-float-left"
        />
        Helium Arches are built with a string of floating helium balloons linked
        together on a single strand. These are great if you want an epic arch that spans across a road or an entire event
        space.
      </p>
            <button className="close-button" onClick={closeFAQModal}>close</button>
          </div>
      ) : (
        <p className="twisting-p" onClick={openFAQModal}>
          FAQ: What does "Arch Style" mean?
        </p>
      )}
    </div>
  );
};

export default FAQArch;
