import React, { useState } from "react";
import "./quickQuote.css";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import iconarch from "../../app/assets/img/iconarch.png";
import iconcolumn from "../../app/assets/img/iconcolumn.png";
import icongarland from "../../app/assets/img/icongarland.png";
import iconcenterpiece from "../../app/assets/img/iconcenterpiece.png";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const QuickQuote = () => {
  const [showQQModal, setShowQQModal] = useState(true);
  const [balloonArch, setBalloonArch] = useState(false);
  const [balloonColumn, setBalloonColumn] = useState(false);
  const [balloonGarland, setBalloonGarland] = useState(false);
  const [balloonCenterpiece, setBalloonCenterpiece] = useState(false);
  const [formData, setFormData] = useState({
    color1: "#ff0000",
    color2: "#ffa500",
    color3: "#ffff00",
    color4: "#008000",
    color5: "#0000ff",
    color6: "#4b0082",
    date: "",
    time: "",
    address: "",
    email: "",
    twister: "",
    img1: null,
    img2: null,
    img3: null,
  });

  const [numColors, setNumColors] = useState(0);
  const [showFile2, setShowFile2] = useState(false);
  const [showFile3, setShowFile3] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleArchToggle = () => {
    setBalloonArch(!balloonArch);
  };
  const handleColumnToggle = () => {
    setBalloonColumn(!balloonColumn);
  };
  const handleGarlandToggle = () => {
    setBalloonGarland(!balloonGarland);
  };
  const handleCenterpieceToggle = () => {
    setBalloonCenterpiece(!balloonCenterpiece);
  };

  const handleNumColorChange = (e) => {
    setNumColors(e.target.value);
  };


  const handleQuoteFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const timestamp = new Date().getTime();
      const id = `${timestamp}_${formData.email}`
      const docRef = await addDoc(collection(db, "QQFormCollection"), {
        "AA":id,
        "Balloon Arch": balloonArch,
        "Balloon Column": balloonColumn,
        "Balloon Garland": balloonGarland,
        "Balloon Centerpiece": balloonCenterpiece,
        "How many colors": numColors,
        "Color 1": formData.color1,
        "Color 2": formData.color2,
        "Color 3": formData.color3,
        "Color 4": formData.color4,
        "Color 5": formData.color5,
        "Color 6": formData.color6,
        "Date": formData.date,
        "Time": formData.time,
        "Address": formData.address,
        "Twister": formData.twister,
        "Image 1": formData.img1,
        "Image 2": formData.img2,
        "Image 3": formData.img3,
        "AA email": formData.email,
      });
      console.log("Document written with ID: ", docRef.id)
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    setShowQQModal(false);
    alert("Thank you, we will contact you about your request within 24 hours");
  };

  const handleCloseQQModal = () => {
    setShowQQModal(false);
  };

  return (
    <Modal isOpen={showQQModal} style={{ marginTop: "100px" }}>
      <ModalHeader toggle={handleCloseQQModal}>
        <h1 className="quote-headline">Quick Quote</h1>
      </ModalHeader>
      <ModalBody>
        <div className="quote-section">
          <h3 className="quote-header">I'm looking for a...</h3>
          <div className="quote-list">
            <div className="quote-row">
              <input type="checkbox" onClick={handleArchToggle}></input>
              <img
                src={iconarch}
                alt="balloon arch icon"
                className="quote-icon"
              />
              <h3 className="quote-text">Balloon Arch</h3>
            </div>
            <div className="quote-row">
              <input type="checkbox" onClick={handleColumnToggle}></input>
              <img
                src={iconcolumn}
                alt="balloon column icon"
                className="quote-icon"
              />
              <h3 className="quote-text">Balloon Column</h3>
            </div>
            <div className="quote-row">
              <input type="checkbox" onClick={handleGarlandToggle}></input>
              <img
                src={icongarland}
                alt="balloon garland icon"
                className="quote-icon"
              />
              <h3 className="quote-text">Balloon Garland</h3>
            </div>
            <div className="quote-row">
              <input type="checkbox" onClick={handleCenterpieceToggle}></input>
              <img
                src={iconcenterpiece}
                alt="balloon centerpiece icon"
                className="quote-icon"
              />
              <h3 className="quote-text">Balloon Centerpiece</h3>
            </div>
          </div>
        </div>

        <hr></hr>

        <div className="quote-section">
          <h3 className="quote-header">Choose your Colors!</h3>
          <select
            name="numColors"
            id="numColors"
            className="form-control"
            onChange={handleNumColorChange}
            value={numColors}
          >
            <option value="">I want...</option>
            <option value="1">just 1 color</option>
            <option value="2">2 colors</option>
            <option value="3">3 colors (recommended)</option>
            <option value="4">4 colors</option>
            <option value="5">5 colors</option>
            <option value="6">6 colors</option>
          </select>
          <p
            className="quote-text"
            id="pickerTitle"
            style={{ display: numColors >= 1 ? "block" : "none" }}
          >
            Select the color(s) you want...
          </p>
          <div className="quote-row">
            <input
              type="color"
              id="color1"
              name="color1"
              value={formData.color1}
              onChange={handleInputChange}
              className="col form-control colorPicker"
              style={{
                display: numColors >= 1 ? "block" : "none",
              }}
            />
            <input
              type="color"
              id="color2"
              name="color2"
              value={formData.color2}
              onChange={handleInputChange}
              className="col form-control colorPicker"
              style={{
                display: numColors >= 2 ? "block" : "none",
              }}
            />
            <input
              type="color"
              id="color3"
              name="color3"
              value={formData.color3}
              className="col form-control colorPicker"
              onChange={handleInputChange}
              style={{
                display: numColors >= 3 ? "block" : "none",
              }}
            />
            <input
              type="color"
              id="color4"
              name="color4"
              value={formData.color4}
              onChange={handleInputChange}
              className="col form-control colorPicker"
              style={{
                display: numColors >= 4 ? "block" : "none",
              }}
            />
            <input
              type="color"
              id="color5"
              name="color5"
              value={formData.color5}
              onChange={handleInputChange}
              className="col form-control colorPicker"
              style={{
                display: numColors >= 5 ? "block" : "none",
              }}
            />
            <input
              type="color"
              id="color6"
              name="color6"
              value={formData.color6}
              className="col form-control colorPicker"
              onChange={handleInputChange}
              style={{
                display: numColors >= 6 ? "block" : "none",
              }}
            />
          </div>
        </div>

        <hr></hr>
        <div className="quote-section">
          <h3 className="quote-header">
            Paste links to 1-3 photos of what you're looking for
          </h3>
          <div className="quote-file-div">
            <input
              type="text"
              name="img1"
              value={formData.img1}
              className="form-control"
              onChange={handleInputChange}
            />
            <button
              className="quote-add-another-button"
              onClick={() => setShowFile2(true)}
              style={{ display: showFile2 ? "none" : "inline" }}
            >
              add another
            </button>
          </div>
          {showFile2 && (
            <div className="quote-file-div">
              <input
                type="text"
                name="img2"
                value={formData.img2}
                className="form-control"
                onChange={handleInputChange}
              />
              <button
                className="quote-add-another-button"
                onClick={() => setShowFile3(true)}
                style={{ display: showFile3 ? "none" : "inline" }}
              >
                add another
              </button>
            </div>
          )}
          {showFile3 && (
            <div className="quote-file-div">
              <input
                type="text"
                name="img3"
                value={formData.img3}
                className="form-control"
                onChange={handleInputChange}
              />
            </div>
          )}
        </div>
        <hr></hr>
        <div className="quote-section">
          <h3 className="quote-header">
            When do you need your Balloon Decor?{" "}
          </h3>
          <input
            type="date"
            value={formData.date}
            name="date"
            onChange={handleInputChange}
            className="form-control quote-date"
          />
          <input
            type="time"
            value={formData.time}
            name="time"
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <hr></hr>
        <div className="quote-section">
          <h3 className="quote-header">Where do you need it delivered? </h3>
          <input
            type="text"
            value={formData.address}
            name="address"
            onChange={handleInputChange}
            className="form-control"
            placeholder="address"
          />
        </div>
        <hr></hr>
        <div className="quote-section">
          <h3 className="quote-header">What's your email? </h3>
          <input
            type="email"
            value={formData.email}
            name="email"
            onChange={handleInputChange}
            className="form-control"
            placeholder="email"
          />
        </div>
        <hr></hr>
        <div className="quote-add-on-section">
          <h3 className="quote-add-on-header">
            Add-on: Would you like a Balloon Twister?{" "}
          </h3>
          <select
            name="twister"
            id="twister"
            className="form-control"
            onChange={handleInputChange}
            value={formData.twister}
          >
            <option value="">Select an option</option>
            <option value="1hr">1 hour ----- $265</option>
            <option value="1.5hr">1.5 hours -- $340</option>
            <option value="2hr">2 hours ---- $415</option>
            <option value="2.5hr">2.5 hours -- $490</option>
            <option value="3hr">3 hours ---- $565</option>
            <option value="3.5hr">3.5 hours -- $640</option>
            <option value="3.5hr +">Over 3.5 hours </option>
          </select>
        </div>
        <hr></hr>
        <div className="quote-submit-container">
          <button
            type="submit"
            className="quote-submit"
            onClick={handleQuoteFormSubmit}
          >
            Get a Quote within 24hrs
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default QuickQuote;
