import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { TESTIMONIALS } from "../app/shared/TESTIMONIALS";
import "./pagescss/testimonialpage.css";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebaseConfig";

const TestimonialPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    rating: "",
    testimonial: "",
  });
  const [formValid, setFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTestimonialFormSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.firstName &&
      formData.lastName &&
      formData.rating &&
      formData.testimonial
    ) {
      try {
        const timestamp = new Date().getTime();
        const id = `${timestamp}_${formData.firstName}`;
        const docRef = await addDoc(collection(db, "TestimonialCollection"), {
          AA: id,
          "Name First": formData.firstName,
          "Name Last": formData.lastName,
          rating: formData.rating,
          Testimonial: formData.testimonial,
        });
        console.log("Document written with ID: ", docRef.id);
        setFormSubmitted(true);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  useEffect(() => {
    if (
      formData.firstName &&
      formData.lastName &&
      formData.rating &&
      formData.testimonial
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);

  return (
    <div>
      <Header
        headline={"Testimonials"}
        headshotDisplay="none"

        tagline={"San Francisco's Globally-Demanded Balloon Artist"}
        display={true}
        className="header"
      />
      {formSubmitted ? (
        <div className="thankyou-container">
          <div className="testimonial-thankyou">
            <h2>Thank you for leaving a Testimonial!</h2>
            <p className="p-black">
              Please consider sending photos and videos of Kevin's work from
              your event to kevinsballoons@gmail.com and please tag
              @kevins.balloons if you post our work on Instagram! Thank you!
            </p>
          </div>
        </div>
      ) : (
        <div className="testimonial-form">
          <h2>Leave a Testimonial</h2>
          <div className="name-rating-testimonial">
            <div className="name-inputs">
              <input
                className="form-control"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <input
                className="form-control"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="rating-select">
              <select
                className="form-control twisting-select"
                name="rating"
                value={formData.rating}
                onChange={handleInputChange}
              >
                <option value="">Rating</option>
                <option value="5">5</option>
                <option value="4">4</option>
                <option value="3">3</option>
                <option value="2">2</option>
                <option value="1">1</option>
              </select>
            </div>
          </div>
          <textarea
            className="form-control"
            name="testimonial"
            placeholder="Tell us about your experience with Kevin's Balloons"
            value={formData.testimonial}
            onChange={handleInputChange}
          ></textarea>
          <button
            className={`submit-button ${formValid ? "active" : ""}`}
            onClick={handleTestimonialFormSubmit}
          >
            Submit
          </button>
        </div>
      )}
      <div className="testimonial-display">
        {TESTIMONIALS.map((testimonial) => (
          <div className="testimonial-card" key={testimonial.id}>
            <h2 style={{ fontFamily: "Alegreya, serif" }}>
              {testimonial.firstName} {testimonial.lastName}
            </h2>
            <p>Rating: {testimonial.rating}</p>
            <p>{testimonial.quote}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialPage;
