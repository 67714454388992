import React, { useState } from "react";
import "./twistingform.css";

const FAQAvailability = () => {
  const [showFAQAvailability, setShowFAQAvailability] = useState(false);

 
  const openFAQModal = () => {
    setShowFAQAvailability(true)
  }
  const closeFAQModal = () =>{
    setShowFAQAvailability(false)
  }
  return (
    <div>
      {showFAQAvailability ? (
          <div className="faq-content">
            <p className="twisting-p">
              Kevin's availability is often limited, and he's often required at
              events abroad, so filling out this form does not guarantee Kevin
              can come to your event. After you submit your information, we will
              check our schedule and send you an email or message regarding
              whether Kevin can make it and how much the event will cost. If
              Kevin cannot make it to your event, but there is a cancellation we
              will immediately let you know he can come to your event. There's
              usually more availability on weekdays, so consider an event at
              your school, daycare, or summer camp!
            </p>
            <button className="close-button" onClick={closeFAQModal}>close</button>
          </div>
      ) : (
        <p className="twisting-p" onClick={openFAQModal}>
          FAQ: What is Kevin's availability?
        </p>
      )}
    </div>
  );
};

export default FAQAvailability;
