import React, { useState } from "react";
// import "./twistingform.css";
import garland12ft from "../app/assets/img/garland12ft.jpg";
import garland6ft from "../app/assets/img/garland6ft.jpg";
import garlanddisplay from "../app/assets/img/garlanddisplay-sq.jpg";
import waterfall from "../app/assets/img/waterfall.jpg";

const FAQGarlandSize = () => {
  const [showFAQGarlandSize, setShowFAQGarlandSize] = useState(false);

  const openFAQModal = () => {
    setShowFAQGarlandSize(true);
  };
  const closeFAQModal = () => {
    setShowFAQGarlandSize(false);
  };
  return (
    <div>
      {showFAQGarlandSize ? (
        <div className="faq-content">
          <p className="service-description">
            Garlands are extremely versatile. Commonly today, they're bent and
            molded around photo backdrops.
          </p>
          <p className="service-description">
            <img
              src={garland6ft}
              alt=""
              className="service-img service-img-float-right"
            />
            A 6ft Balloon Garland is ideal for just highlighting a particular
            area of an event, like the bar.
          </p>
          <p className="service-description">
            <img
              src={garlanddisplay}
              alt=""
              className="service-img service-img-float-left"
            />
            12ft Garlands are the most popular, and are often used to add color
            and texture to a photo backdrop for a birthday or baby shower, or
            adorn half of an entryway for a Grand Opening.
            <img
              src={garland12ft}
              alt=""
              className="service-img service-img-float-right"
            />
          </p>
          <p className="service-description">
            <img
              src={waterfall}
              alt=""
              className="service-img service-img-float-left"
            />{" "}
            While 20ft and 30ft are ideal for grand displays. Drape them over a
            car, down a stairway, hang them across the ceilings or have them
            drape down the front of your building! These are ideal for
            Graduations, Country Club events, Grand Openings, and Holiday
            displays.
          </p>
          <button className="close-button" onClick={closeFAQModal}>
            close
          </button>
        </div>
      ) : (
        <p className="twisting-p" onClick={openFAQModal}>
          FAQ: How big should my Garland be?
        </p>
      )}
    </div>
  );
};

export default FAQGarlandSize;
