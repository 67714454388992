import logoGlassdoor from "../app/assets/img/logo-glassdoor.png";
import logoJasonMomoa from "../app/assets/img/logo-jason-momoa.png";
import logoPlayboy from "../app/assets/img/logo-playboy.png";
import logoJenniferGarner from "../app/assets/img/logo-jennifer-garner.png";
import logoGoogle from "../app/assets/img/logo-google.png";
import logoNrdc from "../app/assets/img/logo-nrdc.png";
import logoBruceWillis from "../app/assets/img/logo-bruce-willis.png";
import logoNomadCruise from "../app/assets/img/logo-nomad-cruise.png";
import logoCoworkingBansko from "../app/assets/img/logo-coworking-bansko.png";
import logoBenAffleck from "../app/assets/img/logo-ben-affleck.png";
import logoNomadSafari from "../app/assets/img/logo-nomad-safari.png";
import logoNomadbase from "../app/assets/img/logo-nomadbase.png";
import "./componentcss/scrollinggallery.css";

function ScrollingLogos() {
  return (
    <>
      <div className="row text-center">
        <h4>If these folks enjoyed Kevin's Balloons, so will you!</h4>
      </div>
      <br/>
      <br/>
      <br/>
      <div className="scrolling-gallery-container">
        <div className="scrolling-marquee">
          <img src={logoGlassdoor} alt="Kevin has made balloons for Glassdoor" className="initiate-marquee" />
          <img src={logoJasonMomoa} alt="Kevin has made balloons for Jason Momoa" />
          <img src={logoPlayboy} alt="Kevin has made balloons for the Playboy Mansion" />
          <img src={logoJenniferGarner} alt="Kevin has made balloons for Jennifer Garner" />
          <img src={logoGoogle} alt="Kevin has made balloons for Google Employees" />
          <img src={logoNrdc} alt="Kevin has made balloons for Natural Resources Defense Council employees" />
          <img src={logoBruceWillis} alt="Kevin has made balloons for Bruce Willis" />
          <img src={logoNomadCruise} alt="Kevin has made balloons for Nomad Cruise" />
          <img src={logoCoworkingBansko} alt="Kevin has made balloons for Coworking Bankso and the Bansko Nomad Fest" />
          <img src={logoBenAffleck} alt="Kevin has made balloons for Ben Affleck" />
          <img src={logoNomadSafari} alt="Kevin has made balloons for Nomad Safari" />
          <img src={logoNomadbase} alt="Kevin has made balloons for Nomadbase" />

          <img src={logoGlassdoor} alt="Kevin has made balloons forGlassdoor" />
          <img src={logoJasonMomoa} alt="Kevin has made balloons for Jason Momoa" />
          <img src={logoPlayboy} alt="Kevin has made balloons for the Playboy Mansion" />
          <img src={logoJenniferGarner} alt="Kevin has made balloons for Jennifer Garner" />
          <img src={logoGoogle} alt="Kevin has made balloons for Google Employees" />
          <img src={logoNrdc} alt="Kevin has made balloons for Natural Resources Defense Council employees" />
          <img src={logoBruceWillis} alt="Kevin has made balloons for Bruce Willis" />
          <img src={logoNomadCruise} alt="Kevin has made balloons for Nomad Cruise" />
          <img src={logoCoworkingBansko} alt="Kevin has made balloons for Coworking Bankso and the Bansko Nomad Fest" />
          <img src={logoBenAffleck} alt="Kevin has made balloons for Ben Affleck" />
          <img src={logoNomadSafari} alt="Kevin has made balloons for Nomad Safari" />
          <img src={logoNomadbase} alt="Kevin has made balloons for Nomadbase" />
        </div>
      </div>
    </>
  );
}

export default ScrollingLogos;
