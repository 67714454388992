import "./componentcss/fab.css";
import { Link } from "react-router-dom";
import QuickQuote from "./quickQuote/quickQuote";
import { useState } from "react";

const FAB = () => {
  const [showQQModal, setShowQQModal] = useState(false);

  return (
    <button
      type="button"
      class="fab"
      onClick={() => setShowQQModal(!showQQModal)}
    >
      <div className="quickquote-text">

      Get your <br/><span>Quick <br/> Quote</span>
      {showQQModal && <QuickQuote />}
      </div>
    </button>
  );
};

export default FAB;
