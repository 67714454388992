import React from "react";
import "./twistingform.css";

const TermsAndConditions = () => {
  return (
    <>
      <p style={{ color: "black" }}>
        As of 2023
        <br />
        These terms and conditions ("Agreement") constitute a legally binding
        agreement between the Balloon Artist ("Artist") and the client
        ("Client"). By booking the Artist's services, the Client agrees to be
        bound by this Agreement.{" "}
      </p>
      <ol>
        <li>
          I agree to be contacted by Kevin's Balloons about my inquiry, and be
          put on the email list - Don't worry, we won't spam up your inbox
        </li>
        <li>
          If you need to cancel an event, that's ok. Just notify us in writing
          as soon as possible. However, if the supplies for the order have
          already been purchased, the Client agrees to reimburse the Artist for
          that amount. If the Artist has already built the arch, garland,
          column, or centerpiece, then the Client agrees to pay the full amount.
        </li>
        <li>
          Either party may terminate this Agreement at any time upon written
          notice to the other party, however, if the Artist must cancel, the
          Client will not be held responsible for paying for any services.
        </li>
        <li>
          The Client agree to pay the balance, agreed upon over email, in CASH
          at the end of the event.
        </li>
        <li>
          There are no refunds for popped balloons, but if time allows you can
          often get a balloon repaired or replaced
        </li>
        <li>
          We cannot guarantee delivery or arrival times, but we will try our
          best to arrive exactly on time.
        </li>
        <li>
          The Artist will not be liable for any damages or losses arising from
          the services provided. The Client is responsible for any damages
          caused to the Artist's equipment or materials during the Event.
        </li>
        <li>
          No balloon decor, balloon twisting service, or any other item is
          considered ordered until confirmed by both parties in writing. Both
          parties must agree on the time, date, location, and services provided.
        </li>
        <li>
          You agree to indemnify and hold harmless Kevin's Balloons and all
          agents, volunteers, employees, friends, and relatives.
        </li>
        <li>
          You agree to indemnify and hold harmless Kevin's Balloons from any
          claims surrounding COVID-19 and other ailments
        </li>
        <li>
          By booking the Artist's services, the Client acknowledges that they
          have read and understood this Agreement and agree to be bound by its
          terms and conditions.
        </li>
      </ol>
    </>
  );
};

export default TermsAndConditions;
