import Header from "../components/Header";
import "./pagescss/businesscard.css";

const BusinessCardPage = () => {
  return (
    <>
      <Header
        headline=""
        headshotDisplay="display"
        tagline={"San Francisco's Globally-Demanded Balloon Artist"}
        display="display"
      />

      <div className="page-content-businesscard">
        <div className="flex-container-businesscard">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2
              style={{
                fontFamily: "modak",
                color: "#ffb200",
                fontSize: "50px",
              }}
            >
              Kevin Camice
            </h2>
          </div>
          <div style={{ textAlign: "center" }}>
            <i className="fa fa-envelope mb-2" size={60} />{" "}
            <a
              href="mailto:kevinsballoons@gmail.com"
              className="contact-info-businesscard"
            >
              kevinsballoons@gmail.com
            </a>
          </div>
          <hr />
          <div>
            <i className="fa fa-instagram mb-2" size={60} />
            <a
              href="https://instagram.com/kevins.balloons"
              className="contact-info-businesscard"
            >
              @Kevins.Balloons
            </a>
          </div>
          <hr />

          <div>
            <i className="fa fa-phone mb-2" size={60} />

            <a href="tel:+19254480369" className="contact-info-businesscard">
              +1(925)448-0369
            </a>
          </div>
          <p style={{ color: "black" }}>
            Please note: to book Kevin, please contact him via the{" "}
            <span className="link-businesscard">
              <a href="https://kevinsballoons.com/book">Booking Form</a>
            </span>
            ,{" "}
            <span className="link-businesscard">
              <a href="mailto:kevinsballoons@gmail.com">Email</a>
            </span>
            , or{" "}
            <span className="link-businesscard">
              <a href="https://instagram.com/kevins.balloons">
                Instagram Direct Message
              </a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default BusinessCardPage;
