import { Row, Col } from "reactstrap";
import "./pagescss/servicepage.css";
import Header from "../components/Header";
import arch16 from "../app/assets/img/arch-superhero-square.jpg";
import garlandimg from "../app/assets/img/balloon-Garlands-ceiling-square.jpg";
import columnimg from "../app/assets/img/flamingoColumns.jpg";
import bikeimg from "../app/assets/img/kev-bike-sq.png";
import twisting from "../app/assets/img/kev-owl2-sm.jpg";
import garnish from "../app/assets/img/balloon-Garnish-parklab.jpg";
import ring from "../app/assets/img/ring-flowers.jpg";
import backdrop from "../app/assets/img/balloon-backdrop-square.jpg";
import bubblehouse from "../app/assets/img/bubble-tent.png";
import { Link, useNavigate } from "react-router-dom";

export const ServiceSection = () => {
  const navigate = useNavigate();

  return (
    <div className="full-width-div">
      <div className="service-div-width">
        <h3 className="homepage-service-header" >
          Customize your Decor for your next Event!
        </h3>

        <Row
          className="service-row ser-yel"
          onClick={() => navigate("/arches")}
        >
          <Col xs="4" className="p-0  d-flex">
            <Link to="/arches">
              <img
                src={arch16}
                alt="balloon arch for a party"
                className="service-img"
              />
            </Link>
          </Col>
          <Col xs="8" className="service-text">
            <h6>Balloon Arches</h6>
            <p> Customize your own Balloon Arch!</p>
            <p className="d-none d-sm-block">
              A balloon arch adds a fun, playful twist to any event and is a
              great way to make a statement and create a memorable photo
              backdrop. <Link to="/arches">Customize your Arch</Link>
            </p>
          </Col>
        </Row>
        <hr></hr>
        <Row
          className="service-row ser-teal"
          onClick={() => navigate("/garlands")}
        >
          <Col xs="8" className="service-text">
            <h6>Balloon Garlands</h6>
            <p> Elevate your next event with a balloon garland!</p>
            <p className="d-none d-sm-block">
              A balloon garland is a fun and festive way to add color and
              texture to your next event. It's versatile, customizable, and
              perfect for creating a photo-worthy backdrop or highlighting key
              areas of your event.{" "}
              <Link to="/garlands">Customize you Garlands</Link>
            </p>
          </Col>
          <Col xs="4" className="p-0 justify-content-center d-flex">
            <Link to="/garlands">
              <img
                src={garlandimg}
                alt="pastel pink balloon garland for an event"
                className="service-img"
              />
            </Link>
          </Col>
        </Row>
        <hr></hr>
        <Row
          className="service-row ser-or"
          onClick={() => navigate("/columns")}
        >
          <Col xs="4" className="p-0 d-flex">
            <Link to="/columns">
              <img
                src={columnimg}
                alt="balloon columns Kevin made for a 50th birthday party event"
                className="service-img"
              />
            </Link>
          </Col>
          <Col xs="8" className="service-text">
            <h6>Balloon Columns</h6>
            <p>Amaze your guests with balloon columns, short or tall!</p>
            <p className="d-none d-sm-block">
              Balloon columns are a must-have at any event! They're versatile,
              customizable, and perfect for adding visual interest and depth to
              your next event! <Link to="/columns">Customize your Columns</Link>
            </p>
          </Col>
        </Row>
        <hr></hr>
        <Row
          className="service-row ser-teal"
          onClick={() => navigate("/centerpieces")}
        >
          <Col xs="8" className="service-text">
            <h6>Custom Centerpieces</h6>
            <p>Turn your idea into a stunning balloon sculpture!</p>
            <p className="d-none d-sm-block">
              A balloon centerpiece sculpture brings a festive flare to any
              event, serving as a unique and eye-catching decoration that will
              wow your guests!{" "}
              <Link to="/centerpieces">Customize your Centerpiece</Link>
            </p>
          </Col>
          <Col xs="4" className="p-0 justify-content-center d-flex">
            <Link to="/centerpieces">
              <img
                src={bikeimg}
                alt="Kevin holding his giant balloon bike centerpiece"
                className="service-img"
              />
            </Link>
          </Col>
        </Row>
        <hr></hr>

        <Row className="service-row ser-or" onClick={() => navigate("/book")}>
          <Col xs="4" className="p-0 d-flex">
            <Link to="/book">
              <img
                src={twisting}
                alt="Kevin holding his giant owl balloon animal"
                className="service-img"
              />
            </Link>
          </Col>
          <Col xs="8" className="service-text">
            <h6>Balloon Twisting</h6>
            <p>Kevin's Specialty!</p>
            <p className="d-none d-sm-block">
              And of course, Kevin is widely regarded as the best Balloon
              Twister in the Bay Area. With mesmerizing speed and precision,
              watch as he brings animals and characters to life right before
              your eyes!
              <Link to="/book"> learn more</Link>
            </p>
          </Col>
        </Row>
        <hr></hr>
        <Row
          className="service-row ser-teal"
          onClick={() => navigate("/contact")}
        >
          <div className="service-text">
          <h6>Ask about our Specialty Decor!</h6>
            <p>Ask about adding elegant Garnishes to your event, epic Ring Garlands, stunning Photo Backdrops for the perfect Instagram shots, and even a fun Bubble House for the kids to play in!</p>
            
          </div>
          <div className="specialty-img-container">
              <img
                src={garnish}
                alt="Kevin holding his giant balloon bike centerpiece"
                className="specialty-img"
              />
              <img
                src={ring}
                alt="Kevin holding his giant balloon bike centerpiece"
                className="specialty-img"
              />
              <img
                src={backdrop}
                alt="Kevin holding his giant balloon bike centerpiece"
                className="specialty-img"
              />
              <img
                src={bubblehouse}
                alt="Kevin holding his giant balloon bike centerpiece"
                className="specialty-img"
              />
          </div>
        </Row>
        
      </div>
    </div>
  );
};
const Servicepage = () => {
  return (
    <>
      <Header
        headline="Balloon Decor & More!"
        headshotDisplay="none"

        tagline="What CAN'T we do?"
        display="none"
      />
      <ServiceSection />
    </>
  );
};

export default Servicepage;
